@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --yellow: #fff200;
    --purple: #9d2ef2;
    --green: #3eff4b;
}

canvas {
    position: fixed;
    top: 0;
    z-index: -1;
}

@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Minecrafter";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/minecrafter.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Onesize";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/onesize.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Onesize";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/onesizer.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: "Onesize", sans-serif;
    scroll-behavior: smooth;

    overflow-x: hidden;
    font-size: 20px;
}
h1,
h2 {
    font-family: "Minecrafter", sans-serif;
    line-height: 1.2;
    font-size: 41px;
}
h1,
h2 {
    &.text-blue {
        text-shadow: 1px 1px 1px white;
    }
}
h3,
h4 {
    font-family: "Onesize", sans-serif;
    font-weight: 700;
    font-size: 28px;
}
.shdw {
    text-shadow: 1px 1px 1px white;
}

/* Loading animation */
@keyframes loading {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.loading div:first-child {
    animation: loading 0.7s infinite;
}
.loading div:nth-child(2) {
    animation: loading 0.6s infinite;
}
.loading div:nth-child(3) {
    animation: loading 0.5s infinite;
}
.loading div:nth-child(4) {
    animation: loading 0.4s infinite;
}

/* Navbar */

.active .nav-bars {
    span {
        &:first-child {
            transform: rotate(45deg) translate(8px, -6px) scale(0.5);
        }
        &:nth-child(2) {
            transform: scaleX(0) scale(0.5);
        }
        &:last-child {
            transform: rotate(-45deg) translate(23px, -9px) scale(0.5);
        }
    }
}

/* FX */
.white-fade {
    background: #ffffffb3;
    box-shadow: 0 0 70px 100px #ffffffb3;
}
.fx-old-lens {
    backdrop-filter: hue-rotate(-55deg) blur(3px);
}

.fx-new-lens {
    backdrop-filter: hue-rotate(-25deg) blur(3px);
}
